/* eslint-disable */
// if (process.env.NODE_ENV === 'development') {
// __webpack_public_path__ = '//qmci-static.s3.us-west-1.amazonaws.com/qmod/tools/qmod/qmodii/dev/code/';
// }
// else {
//   __webpack_public_path__ = "//qmci-static.s3.us-west-1.amazonaws.com/qmod/tools/qmod/qmodii/dev/code/";
// }

__webpack_public_path__ = "https://static.c1.quotemedia.com/qmod/qmodii/v1.72.1/";


import "@babel/polyfill";
import "vuetify/src/stylus/app.styl";

import Vue from "vue";

// import './plugins/vuetify'
import qModii from "./App.vue";
import store from "./store";
import qModCore from "./mixins/qmod";
import VueI18n from "vue-i18n";
import messages from "./locales/";

import Vuetify, {
  VApp, // required
} from "vuetify/lib";
// import { Ripple } from "vuetify/lib/directives";
import transitions from "vuetify/es5/components/transitions";

// async function loadLocale(locale) {
//   return await import(`./locales/${locale}.json`)
// }

let qModVUE;
let qModTheme = {
  primary: '#1976D2',
  secondary: '#424242',
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107'
}


Vue.use(VueI18n);
Vue.use(Vuetify, {
  components: {
    VApp,
    transitions,
  }
});

Vue.use(qModCore, store);
Vue.config.productionTip = false;

export async function init(
  qmWMID,
  qmComponentDOMElement,
  qmComponentName,
  qmComponentProps,
  qmLanguage = "en",
  qmEnv = "app",
  qmSID = null
) {

  const i18n = new VueI18n({
    locale: qmLanguage,
    messages,
  });
  
  qmComponentProps.qmLanguage = qmLanguage;
  qModVUE = new Vue({
    i18n,
    store,
    render: (h) =>
      h(qModii, {
        props: {
          qmWMID: qmWMID,
          qmDOMid: qmComponentDOMElement.replace("#", ""),
          qmComponentName: qmComponentName,
          qmComponentProps: qmComponentProps,
          qmEnv,
          qmSID,
        },
      }),
  }).$mount(qmComponentDOMElement);
  qModVUE.$vuetify.theme = qModTheme;
}

export function changeLang(lang) {
  qModVUE.$i18n.locale = lang;
}

export function setTheme(theme) {
  qModTheme = theme;
}

if (typeof window.CustomEvent === "function") {
} else {
  function CustomEvent(event, params) {
    params = params || {
      bubbles: false,
      cancelable: false,
      detail: undefined,
    };
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;
  window.CustomEvent = CustomEvent;
}

document.addEventListener(
  "DOMContentLoaded",
  () => {
    var evt = new CustomEvent("QMOD:LOADER:FINISHED");
    window.dispatchEvent(evt);
  },
  false
);

window.init = init;
